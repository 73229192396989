.footer-container {
    background: var(--darker-primary);
    padding-top: 5rem;
    padding-bottom: 3rem;
    color: var(--white)
}

.footer-container .container {
    padding: 0 2rem;
    display: flex;
    justify-content: space-between;
}

.footer-container .logo-container,
.footer-container .links-container,
.footer-container .shop-container {
    flex: auto;
}

.logo-container {
    font-size: 1.5rem;
    font-family: var(--secondary-font);
}

.logo-container a {
    color: var(--white);
    text-decoration: none;
}

.footer-container .links-container a,
.footer-container .shop-container a {
    margin-top: .5rem;
    display: block;
    text-decoration: none;
    color: var(--white);
}

@media screen and (max-width: 600px) {

    .footer-container .logo-container {
        width: 100%;
        text-align: center;
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid var(--white);
    }
    
    .footer-container .container {
        flex-direction: row;
        flex-flow: wrap;
        text-align: center;
    }
}