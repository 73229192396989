.btn {
    border: none;
    outline: none;
    padding: 1.25rem 2rem;
    font-size: 1rem;
    font-family: var(--primary-font);
    position: relative;
    z-index: 99999;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
}

.btn:hover {
    cursor: pointer;
}

.btn-primary {
    color: var(--white);
    background: var(--dark-primary);

    transition: all 0.5s ease;
}
.btn-primary:hover {
    background: var(--darker-primary);
}

.btn-large {
    font-size: 1.05rem;
}

.btn-small {
    padding: 0.9rem 1.65rem;
    font-size: 1rem;
}

.btn-stretch {
    width: 100%;
}

.btn i {
    transform: scale(.8) translateY(10%);
    margin-left: .4rem;
}

.btn a {
    color: var(--white);
    text-decoration: none;  
}

.btn.btn-disabled {
    cursor: not-allowed;
    background: gray;
}

@media screen and (max-width: 500px) {
    .btn {
        font-size: .8rem;
        padding: 1rem 1.6rem;
    }
}

@media screen and (max-width: 375px) {
    .btn {
        font-size: .7rem;
        padding: .75rem 1.3rem;
    }
}