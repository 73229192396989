.product-container {
    max-width: 250px;
    margin: 0 2.25rem;
    margin-bottom: 6rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    flex-shrink: 1;
}


.product-container .product-img {
    flex: 1;
    max-height: 400px;
}

.product-container img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.product-container .product-content {
    margin-top: 1rem;
}

.product-content .product-name {
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--darker-primary);
    text-transform: uppercase;
    margin-bottom: .4rem;
}

.product-content .product-description {
    font-weight: 500;
    opacity: 0.4;
    font-size: .8rem;
    margin-bottom: 0.6rem;
}

.product-content .product-price {
    opacity: 0.5;
    font-size: 1rem;
    margin-bottom: 1.25rem;
}

@media screen and (max-width: 1150px) {
    .product-container .product-content .product-name {
        font-size: 1.15rem;
    }

    .product-container .product-description {
        font-size: .7rem;
    }
}

@media screen and (max-width: 500px) {
    .product-container {
        margin: 0 1rem;
        margin-bottom: 4rem;
    }

    .product-container .product-content .product-name {
        font-size: 1.1rem;
    }
    
    .product-container .product-description {
        font-size: .65rem;
    }

    
    .product-container .product-img {
        max-height: 190px;
    }
}

@media screen and (max-width: 300px) {
    .product-container .product-content .product-name {
        font-size: .8rem;
    }
    .product-container .product-description {
        font-size: .6rem;
    }
}