.checkout-page-container {
    padding-top: 11rem;
    background: #F0F0EB;
    min-height: 100vh;
}

.checkout-page-container .container {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

@media screen and (max-width: 768px) {
    .checkout-page-container .container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
    }
}