.cart-item {
    width: 100%;
    padding: 1rem 0;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    grid-template-rows: auto;
    border-bottom: 1px solid #3331;
    position: relative;
    margin-bottom: 1rem;
}

.cart-item a {
    text-decoration: none;
    color: inherit;
}

.cart-item .item-description {
    display: flex;
}
.item-description .item-img {
    width: 100px;
    height: 100px;
    margin-right: 1rem;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.item-description .item-content h3 {
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--darker-primary);
    margin-bottom: .6rem;
}

.item-description .desc {
    font-size: .9rem;
    color: var(--black);
    opacity: 0.5;
}

.item-description .item-content .single-price {
    opacity: 0.5;
}
.item-total {
    display: flex;
    align-items: center;
    color: var(--darker-primary);
    font-size: 1.1rem;
}

.item-quantity {
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.item-quantity .quantity-control {
    display: flex;
    align-items: center;
    
}
.quantity-control .minus, 
.quantity-control .plus {
    padding: 0 .5rem;
    width: 30px;
    font-size: 1.4rem;
    opacity: .6;
    text-align: center;
    border: 1px solid #3335;
    cursor: pointer;
}
.quantity-control .quantity {
    padding: 0 1rem;
    font-size: 1.1rem;
    height: 100%;
    border-top: 1px solid #3335;
    border-bottom: 1px solid #3335;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cart-item-delete {
    position: absolute;
    right: 5%;
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.2rem;
    opacity: 0.5;
    cursor: pointer;
}

@media screen and (max-width: 660px) {
    .item-description .item-img {
        width: 75px;
        height: 75px;
    }
}

@media screen and (max-width: 600px) {
    .item-description .item-img {
        min-width: 50px;
        max-width: 50px;
        min-height: 50px;
        max-height: 50px;
        margin-right: .6rem;
    }
    
    .cart-item .item-content h3 {
        font-size: 1rem;
        margin-bottom: 0;
    }
    .cart-item .item-content .desc {
        display: none;
    }
    
    .cart-item .item-content .single-price {
        font-size: .7rem;
    }
    .cart-item .item-quantity .quantity-control .minus, 
    .cart-item .item-quantity .quantity-control .plus {
        font-size: 1.1rem;
        padding: 0 .5rem;
        width: auto;
    }   
    .cart-item .item-quantity .quantity-control .quantity {
        font-size: .8rem;
        padding: 0 .75rem;
    }
    
    .cart-item .item-total p {
        font-size: .8rem;
    }
}

@media screen and (max-width: 400px) {
    .item-description .item-img {
        min-width: 40px;
        max-width: 40px;
        min-height: 40px;
        max-height: 40px;
        margin-right: .5rem;
    }

    .cart-item .quantity-control {
        transform: scale(.8);
    }

    .cart-item .item-content h3 {
        font-size: .7rem;
    }
    .cart-item .item-content .single-price {
        font-size: .6rem;
    }

    .cart-item .item-total p {
        font-size: .6rem;
    }

    .cart-item-delete {
        right: 0;
    }
}