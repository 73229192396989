.gallery-container {
    margin-top: 15rem;
    padding: 1rem;
}

.gallery-container h2 {
    font-size: 2rem;
    font-weight: 400;
    color: var(--primary-color);
}

.gallery {
    margin-top: 3rem;
    margin-bottom: 5rem;
}

.gallery-container img {
    width: 100%;
    padding: 1rem;
}

.four-layer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
}

.two-layer {
    display: flex;
    width: 100%;
}
.two-layer img {
    height: 100%;
}

@media screen and (max-width: 720px) {
    .four-layer {
        grid-template-columns: repeat(2, 1fr);
    }
    .two-layer {
        display: flex;
        height: auto;
    }

    .two-layer .img-cont:last-child {
        display: none;
    }
    
    .gallery-container.container h2 {
        padding-left: 1rem;
    }
}

@media screen and (max-width: 400px) {
    .four-layer {
        grid-template-columns: repeat(1, auto);
    }
}