/* .loading-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-sign {
    width: 60px;
    height: 60px;
    background: var(--primary-color);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-sign-inside {
    width: 45px;
    height: 45px;
    background: var(--white);
    border-radius: 50%;
    position: relative;

    animation: spin 1s infinite linear;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
    
}

.loading-sign-inside::after {
    content: '';
    position: absolute;
    width: 15px;
    height: 30px;
    background: var(--white);
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
}

*/

.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
    display: flex;
  }
  .lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid var(--primary-color);
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-color) transparent transparent transparent;
  }
  .lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
  }
  .lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
  }
  .lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  