.product-list-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.product-list-header {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.product-list-header h3 {
    font-size: 2rem;
    font-weight: 400;
    color: var(--dark-primary);
    padding-right: 3rem;
} 

.search-bar {
    padding: .75rem 1.1rem;
    border-bottom: 1px #33333333 solid;
    border-left: 1px #33333333 solid;
}

.search-bar i {
    transform: scale(0.95);
    opacity: 0.3;
}

.search-bar input {
    font-size: 1rem;
    background: var(--white);
    border: none;
    outline: none;
    padding-left: 1rem;
}

@media screen and (max-width: 1150px) {
    .product-list-container {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .product-list-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        justify-items: center;
    }

    .product-list-header h3 {
        font-size: 1.5rem;
        padding-right: 1rem;
    }

    .search-bar input {
        font-size: .9rem;
    }
}

@media screen and (max-width: 500px) {
    .search-bar input {
        font-size: .75rem;
        padding-left: .5rem;
        min-width: 0 !important;
        width: auto;
    }

    .search-bar {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 350px) {
    .search-bar input {
        font-size: .65rem;
    }

    .search-bar i {
        display: none;
    }
}