.order-summary {
    width: 100%;
    padding: 2rem;
    padding-top: 0;
    height: auto;
    color: var(--black);
}

.order-summary-container {
    background: #fff;
    padding: 2rem;
}

.order-summary h2 {
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: 2rem;
}

.order-summary .cart-total h3 {
    font-size: 1.75rem;
    font-weight: 400;
    margin-bottom: .75rem;
}

.order-summary .price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: .25rem;
}

.order-summary .price:last-child {
    font-weight: 500;
    font-size: 1.2rem;  
}

@media screen and (max-width: 768px) {
    .order-summary {
        padding: 1rem;
        margin-bottom: 5rem;
    }
}