.about-container {
    background: #f0f0eb;
    padding-top: 1rem;
}

.about-container .container {
    display: grid;
    grid-template: "a b" 
                   "c b";
    min-height: 100vh;
    align-items: center;
    margin-top: 7rem;
    padding-bottom: 5rem;
}

.about-container .about-main-content {
    grid-area: a;
}
.about-container .about-side-content {
    grid-area: c;
}
.about-container .about-image {
    grid-area: b;
}

.about-container img {
    width: 100%;
}

.about-container .about-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 2rem;
}

.about-container .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--black);
}

.about-main-content .main-text {
    margin-left: 2rem;
    font-size: 2.4rem;
    font-weight: 400;
    font-family: var(--secondary-font);

}

.about-side-content .side-text {
    margin-left: 2rem;
    padding-right: 2rem;
    opacity: .9;
    font-size: .9rem;
}

.about-main-content,
.about-side-content {
    padding-right: 2rem;
    display: flex;
    width: 100%;
    height: 100%;
}

.about-main-content {
    align-items: flex-end;
    margin-bottom: 3rem;
}
.about-side-content {
    margin-top: 3rem;
    align-items: flex-start;
}

.phone-about-content {
    display: none;
}
@media screen and (max-width: 1000px) {
    .about-main-content .main-text {
        font-size: 2rem;
    }

    .about-side-content .side-text {
        font-size: .8rem;
    }
}

@media screen and (max-width: 768px) {
    .about-container .container {
        grid-template: "a a" 
                       "c b";
    }

    .about-side-content {
        align-items: flex-start;
        margin-top: 5rem;
    }
    .about-container .about-image {
        height: 100%;
        align-items: flex-start;
    }
}

@media screen and (max-width: 500px) {
    .about-main-content, .about-side-content, .about-image {
        display: none !important;
    }

    .about-container .phone-about-content {
        display: block;
        padding: 2rem;
    }

    .phone-about-content .about-header {
        margin-bottom: 1.5rem;
    }
    .about-header p {
        font-size: .8rem;
        font-weight: 600;
        opacity: 0.6;
        color: var(--black);
    }
    .about-header h2 {
        font-size: 10vw;
        font-weight: 400;
        color: var(--darker-primary);
    }

    .about-text-section .text-container {
        color: var(--black);
        margin-bottom: 3.5rem;
        text-align: center;
    }

    .about-text-section .text-label {
        font-size: .8rem;
        font-weight: 600;
        opacity: 0.6;
        margin-bottom: .5rem;
    }
    .text-label i {
        margin-right: .75rem;
        transform: scale(1.2);
    }
    .about-text-section .main-p {
        font-weight: 500;
        opacity: .8;
        font-size: 1.3rem;
        font-family: var(--secondary-font);
        margin-bottom: 7rem;
        text-align: left;
    }

    .phone-about-content .small-about-img {
        width: 100%;
        margin-top: 7rem;
    }
}