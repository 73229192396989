.cart-list-container {
    padding: 0 1rem;
    width: 100%;
}

.cart-list-container .text-layer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 4rem;
}

.text-layer h2 {
    font-size: 2rem;
    font-weight: 400;
    color: var(--primary-color);
}

.text-layer a {
    color: var(--black);
    opacity: 0.5;
    text-decoration: none;
}

.list .list-header {
    margin-top: 1rem;
    display: grid;
    grid-template-columns: 1.5fr 1fr 1fr;
    grid-template-rows: auto;
    font-size: .8rem;
    opacity: 0.5;
    font-weight: 500;
    padding-bottom: .5rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #3333;
}

.cart-list-container .empty-cart {
    font-size: 1.3rem;
    color: var(--black);
}

@media screen and (max-width: 768px) {
    .text-layer h2 {
        font-size: 1.75rem;
    }
    .text-layer a {
        font-size: .8rem;
    }

    .cart-list-container .list-header p {
        font-size: .7rem;
    }
}

@media screen and (max-width: 420px) {
    .cart-list-container {
        padding: 0;
    }
}