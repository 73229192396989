/* Vars */
:root {
    --white: #fafafa;
    --black: #333333;
    --primary-color: #A8A485;
    --dark-primary: #817E68;
    --darker-primary: #605e4e;

    --primary-font: 'Roboto Slab', 'Arial';
    --secondary-font: 'Lora', 'Serif';

    --container-width: 1250px
}

/* Reset */
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Element Styling */
html {
    scroll-behavior: smooth;
}

html, body, #root, .App {
    height: 100%;
}

body {
    background: var(--white);
    line-height: 1.4;
    font-family: var(--primary-font);
}

/* Utility Classes */
.container {
    margin: auto;
    max-width: var(--container-width);
}