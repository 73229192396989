.order-form .input-container {
    margin-top: 1rem;
}

.order-form .input-container:first-of-type {
    margin-top: 1.5rem;
}

.order-form .input-container input {
    border: none;
    outline: none;
    display: block;
    width: 100%;
    padding: .6rem .8rem;
    font-family: var(--font-family);
    margin-top: .5rem;
    border-radius: 3px;
    border: 2px solid #3331;
}

.order-form .input-container.not-valid input {
    border: 2px solid #ff000077;
    background: #ff000011;
}

.order-form .input-container label {
    font-size: .9rem;
    font-weight: 400;
    opacity: .6;
}

.order-form .input-container.not-valid label {
    color: #f00;
}