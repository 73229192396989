.landing-page-container {
    background-color: var(--primary-color);
    color: var(--white);
    height: 100%;
    max-height: 1080px ;
    display: flex;
    flex-direction: column;
    position: relative;
}

.landing-page-container .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.landing-page-container .landing-content {
    flex-direction: column;
    width: 100%;
    padding-top: 7rem;
}

.landing-page-container .text-container {
    text-align: center;
    margin-bottom: 5rem;
}

.text-container .background-text {
    opacity: 0.6;
    font-weight: 500;
    font-size: 0.9rem;
}
.text-container .main-text {
    font-weight: 300;
    font-size: 3.5rem;
}

.photo-gallery {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: calc(var(--container-width) + 100px);
    margin: auto;
    display: flex;
    justify-content: space-between;
}

.photo-gallery img {
    width: 200px;
    z-index: -1;
}

.photo-gallery .photos {
    position: relative;
}


.photos.left {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.photos.left img:nth-child(1) {
    position: relative;
    z-index: 1;
    width: 135px;
    bottom: 0;
    left: 0;
}
.photos.left img:nth-child(2) {
    position: absolute;
    left: 60px;
    bottom: 110px;
    z-index: 0;
    width: 220px;
}
.photos.left img:nth-child(3) {
    position: absolute;
    bottom: 0;
    left: 135px;
    z-index: 3;
    width: 220px;
}

.photos.right img:nth-child(2) {
    position: relative;
    bottom: 190px;
    width: 220px;
}
.photos.right img:nth-child(1) {
    position: absolute;
    bottom: 0;
    right: 100px;
    width: 220px;
}

@media screen and (max-width: 1000px) {
    .photos.left img:nth-child(1) {
        width: 110px;
    }
    .photos.left img:nth-child(2) {
        width: 160px;
    }
    .photos.left img:nth-child(3) {
        left: 110px;
        width: 160px;
    }
    
    .photos.right img:nth-child(2) {
        bottom: 160px;
        width: 160px;
    }
    .photos.right img:nth-child(1) {
        right: 80px;
        width: 160px;
    }
}

@media screen and (max-width: 768px) {
    .photo-gallery {
        bottom: 0;
    }
    
}

@media screen and (max-width: 600px) {
    .landing-page-container .landing-content {
        padding-top: 0;
    }

    .landing-page-container .landing-content .text-container .main-text {
        font-size: 2.7rem;
    }
    .landing-page-container .landing-content .text-container .background-text {
        font-size: .8rem;
    }
    .landing-page-container .text-container {
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 500px) {
    .photos.left img:nth-child(2),
    .photos.photos.left img:nth-child(3) {
        display: none;
    }

    .photos.right img:nth-child(2) {
        display: none;
    }

    .photos.left img:first-child {
        width: 120px;
    }

    .photos.right img:nth-child(1) {
        width: 120px;
        bottom: 0;
        right: 0;
    }

    .landing-page-container .landing-content .text-container .main-text {
        font-size: 2.2rem;
    }
    .landing-page-container .landing-content .text-container .background-text {
        font-size: .7rem;
    }
}