.contact-container {
    background: var(--primary-color);
    padding: 10rem 2rem;
    color: var(--white);
}

.contact-container .container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-container .contact-image img {
    width: 100%;
}

.contact-container .contact-image {
    padding: 2rem;
    margin-right: 4rem;
}

.contact-container .contact-image,
.contact-container .contact-content {
    flex: 1;
}

.contact-container .contact-message {
    font-size: 4rem;
    font-weight: 400;
    margin-bottom: 1rem;
}

.contact-form {
    width: 100%;
}

.double-input {
    display: flex;
}

.double-input .input-container:first-child {
    margin-right: 2rem;
}

.contact-container .input-container {
    width: 100%;
    padding: 1rem 0rem 0.5rem 0;
}

.contact-container .input-container .input {
    outline: none;
    border: none;
    padding: .75rem 1rem;
    font-size: 1rem;
    background: var(--white);
    resize: none;
    width: 100%;
    margin-top: 1rem;
}

.contact-container .input-container label {
    font-size: 1.5rem;
    font-weight: 300;
}

.contact-container .input-container textarea.input {
    padding: 1.5rem 1.5rem;
    font-family: var(--primary-font);
    margin-bottom: 2rem;
}

@media screen and (max-width: 850px) {
    .contact-container .container {
        flex-direction: column-reverse;
    }

    .contact-image {
        display: none;
    }
}

@media screen and (max-width: 500px) {
    .contact-container .contact-content .contact-message {
        font-size: 3rem;
    }
}
@media screen and (max-width: 400px) {
    .double-input {
        flex-direction: column;
    }
}

@media screen and (max-width: 281px) {
    .contact-container .contact-content .contact-message {
        font-size: 2.4rem;
    }
}