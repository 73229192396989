.best-sellers-container {
    padding-top: 10rem;
    background: #fafafa;
    margin-bottom: 7rem;
}


.best-sellers-container .products-text {
    color: var(--dark-primary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
}

.best-sellers-container h2 {
    font-size: 2.5rem;
    font-weight: 400;
}

.best-sellers-container .product-list {
    margin-top: 3.5rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.products-text a {
    text-decoration: none;
    color: var(--black);
    opacity: 0.7;
    font-size: 1.1rem;
}

@media screen and (max-width: 1150px) {
    .best-sellers-container  .product-list-container .product-container:not(:nth-child(-n+3)) {
        display: none !important;
    }

    .best-sellers-container h2 {
        font-size: 2rem;
    }

    .products-text a {
        font-size: 1rem;
    }
}

@media screen and (max-width: 768px) {

    .best-sellers-container  .product-list-container .product-container:not(:nth-child(-n+3)) {
        display: block !important;
    }
}

@media screen and (max-width: 500px) {
    .best-sellers-container h2 {
        font-size: 1.75rem;
    }

    .products-text a {
        font-size: .85rem;
    }

    .best-sellers-container .products-text {
        padding-left: 1.5rem;
        padding-right: 1.5rem;
    }
}