.product-page-container {
    margin-top: 10rem;
    display: flex;
}

.product-page-container .product-images,
.product-page-container .single-product-content {
    flex: 1
}

.product-page-container img {
    width: 100%;
}

.product-images {
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.product-images .small-images {
    display: flex;
    flex-direction: column;
}

.product-images .small-image {
    width: 80px;
    height: 80px;
    margin-right: 1rem;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-bottom: .25rem;
    cursor: pointer;
}

.product-images .main-image {
    margin-right: 2rem;
}

.single-product-content {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    margin-left: 2rem;
    margin-top: 2rem;
    color: var(--black);
}

.single-product-content .prd-name,
.small-screen-title {
    font-weight: 400;
    font-size: 2rem;
    text-transform: uppercase;
}

.single-product-content .prd-short-desc {
    font-size: 1.1rem;
    opacity: 0.6;
    margin-bottom: 2rem;
}

.single-product-content .prd-price {
    font-size: 2rem;
    font-weight: 500;
    color: var(--primary-color);
    margin-bottom: 2rem;
}

.single-product-content .prd-description {
    font-size: 1rem;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
}

.prd-description .desc-label-container {
    margin-bottom: 1rem;
}

.prd-description .description-label {
    color: #000;
    position: relative;
}

.prd-description .description-label::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    width: 100%;
    height: 2px;
    background: var(--black);
    opacity: .6;
    transform: translateX(-50%);
}

.single-product-content .prd-buttons {
    display: flex;
}

.prd-buttons .quantity-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
    border: solid var(--black) 1px;
    margin-right: 1rem;
}

.prd-buttons .quantity-btn .plus {
    margin-right: 0;
}
.prd-buttons .quantity-btn .plus,
.prd-buttons .quantity-btn .minus {
    font-size: 1.2rem;
    cursor: pointer;
    user-select: none;
}
.prd-buttons .quantity-btn .minus,
.prd-buttons .quantity-btn .quantity {
    margin-right: .75rem;
    font-weight: 500;
}
.prd-buttons .quantity-btn .text {
    font-weight: 500;
    margin-right: 1.25rem;
}

.small-screen-title,
.small-screen-short-desc {
    display: none;
}

@media screen and (max-width: 1000px) {
    .single-product-content .prd-name h2 {
        font-size: 2.5rem;
    }
    .single-product-content .prd-short-desc {
        margin-bottom: 1.5rem;
    }

    .single-product-content .prd-price p {
        font-size: 1.75rem;
    }
    .single-product-content .prd-price {
        margin-bottom: 1.5rem;
    }

    .single-product-content .prd-description {
        font-size: .9rem;
    }

    .single-product-content .prd-buttons .quantity-btn {
        padding: .75rem 1.5rem;
    }

    .single-product-content .prd-buttons .quantity-btn .text {
        font-size: .9rem;
    }
    .single-product-content .prd-buttons .quantity-btn .quantity {
        font-size: 1rem;
    }

}

@media screen and (max-width: 768px) {
    .small-screen-title {
        display: block;
        font-weight: 600;
        font-size: 2.5rem;
        color: var(--black);
        width: 100%;
        margin-top: 1rem;
    }

    .small-screen-short-desc {
        display: block;
        margin-bottom: 2rem;
        width: 100%;
        color: var(--black);
        opacity: 0.6;
    }

    .prd-name,
    .prd-short-desc {
        display: none !important;
    }

    .product-page-container {
        flex-direction: column;
        align-items: center;
    }
    
    .product-page-container .product-images {
        max-width: 450px;
        padding: 2rem;
        flex-direction: column-reverse;
    }

    .product-page-container .product-images .main-image {
        margin-right: 0;
        margin-bottom: 1rem;
    }

    .product-page-container .product-images .small-images {
        flex-direction: row;
    }

    .product-page-container .product-images .small-image {
        margin-left: .25rem;
        margin-right: .25rem;
        margin-bottom: 1rem;
    }

    .product-page-container .single-product-content {
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        margin-top: .5rem;
    }
}

@media screen and (max-width: 400px) {
    .single-product-content .prd-buttons .quantity-btn {
        padding: .5rem 1rem;
    }

    .single-product-content .prd-buttons .quantity-btn .text {
        display: none;
    }
    .single-product-content .prd-buttons .quantity-btn .quantity {
        font-size: .9rem;
    }
}