.order-form {
    width: 100%;
    padding-right: 3rem;
    color: var(--black);
    margin-bottom: 5rem;
}

.order-form h2 {
    font-weight: 400;
    font-size: 1.75rem;
}

.payment-methods-container {
    margin-top: 4rem;
}

.payment-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    background: #fff;
    padding: 1rem;
    margin-top: 1.5rem;
    border-radius: 2px;
}

.card-icons,
.paypal-icon {
    justify-self: flex-end;
}

.card-icons svg {
    width: 30px;
    height: 100%;
}

.paypal-icon svg {
    width: 80px;
    height: 100%;
}

.order-form .payment-container .input-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    margin-top: 0;
    padding-top: 0;
}
.payment-container .input-container input {
    width: auto;
    margin-top: 0;
    margin-right: .5rem;
}

.payment-container .input-container label {
    font-size: 1rem;
    opacity: 1;
    color: var(--black);
}

.order-form button {
    margin-top: 2rem;
}

.stripe-element-container {
    background: #fff;
    margin-top: 1rem;
    padding: 1rem;
}

.paypal-payment-container {
    margin-bottom: 1rem;
}

.hidden {
    display: none;
}

@media screen and (max-width: 768px) {
    .order-form {
        padding-right: 0;
        padding: 1rem;
    }
}