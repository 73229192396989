.error-container {
    width: 100%;
    padding: 1rem;
    display: flex;
    background: #FFCDD2;
    border-radius: 10px;
    font-size: .8rem;
    color: #f00;
    font-weight: 500;
    margin-bottom: 2rem;
    border: 2px solid #eec2c2;
}

.error-icon {
    margin-right: 1rem;
    margin-left: .5rem;
    transform: scale(1.2);
}