.cart-item-summary-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: .75rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #3332;
}

.cart-item-summary-container .item-content {
    display: flex;
}

.cart-item-summary-container .item-text {
    margin-left: 1rem;
}

.cart-item-summary-container .item-title {
    font-size: 1.1rem;
}

.cart-item-summary-container .item-desc {
    font-size: .8rem;
    opacity: .6;
}

.cart-item-summary-container .item-img {
    width: 60px;
    height: 60px;

    background-size: cover;
    background-position: center center;
}

.cart-item-summary-container .item-price {
    font-size: 1.1rem;
    font-weight: 500;
}

.cart-item-summary-container .quantity {
    font-size: .9rem;
    opacity: 0.6;
}

@media screen and (max-width: 450px) {
    .cart-item-summary-container .item-img {
        width: 50px;
        height: 50px;
    }
    .cart-item-summary-container .item-price {
        font-size: .9rem;
    }
    .cart-item-summary-container .quantity {
        font-size: .75rem;
    }
    .cart-item-summary-container .item-title {
        font-size: .9rem;
    }
    .cart-item-summary-container .item-desc {
        font-size: .75rem;
    }
}