.navbar {
    padding: 1.5rem 1rem;
    padding-top: 3rem;
    font-family: var(--secondary-font);
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 9999999999;
    background: var(--primary-color);
    color: var(--white);

    transition: all 0.3s ease-out;
}

.navbar a {
    color: var(--white);
    text-decoration: none;
}

.navbar .container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar .company-info {
    flex: auto;
}

.navbar .static-menu,
.navbar .shop-menu {
    flex: 1
}

.navbar .static-menu {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navbar .menu-list {
    display: flex;
    list-style: none;
}

.navbar .static-menu .menu-list {
    margin-left: 1rem;
}

.navbar .menu-icon-container {
    display: none;
}
.navbar .menu-icon {
    width: 20px;
    height: 2px;
    background: var(--white);
    position: relative;
    transform: translateY(-2.5px);
}
.navbar .menu-icon-container:hover {
    cursor: pointer;
}
.navbar .menu-icon::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0%;
    width: 80%;
    height: 2px;
    background: var(--white);
}

.navbar .logo-container {
    width: 40px;
}
.navbar .logo-container img {
    width: 100%;
}

.navbar .menu-list .menu-link {
    margin: 0 .85rem;
    font-size: .9rem;
    position: relative;
    
}
.navbar .menu-list .menu-link:hover {
    cursor: pointer;
}

.navbar .menu-list .menu-link::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -3px;
    width: 0;
    height: 1px;
    background: var(--white);
    transform: translateX(-50%);
    transition: width 1s ease;
}
.navbar .menu-list .menu-link:hover::after {
    width: 100%;
}

.navbar .company-info {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.navbar .company-info .company-link {
    font-weight: 300;
    font-size: 1.7rem;
    transition: all 0.3s ease-out;
}

.navbar .shop-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.navbar .shop-menu .menu-list-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;    
}

.navbar .shop-menu .cart-container:hover {
    cursor: pointer;
}

.navbar .shop-menu .cart-container {
    position: relative;
    transform: scale(1.1);
}

.cart-container .item-num-showcase {
    font-size: .7rem;
    position: absolute;
    right: 0;
    bottom: 2px;
    width: 15px;
    height: 15px;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.navbar .phone-menu {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: #333a;
    display: flex;
}

.navbar .phone-menu .phone-menu-links {
    transform: translateX(-100%);
    
    transition: all 0.7s ease;
}
.navbar .phone-menu.visible .phone-menu-links {
    transform: translateX(0);
    transition: all 0.7s ease;
}


.navbar .phone-menu {
    visibility: hidden;   
}

.navbar .phone-menu.visible {
    visibility: visible;
}

.navbar .phone-menu .phone-menu-links {
    background: var(--primary-color);
    min-height: 100%;
    height: 100%;
    padding: 2rem;
    font-size: 1.3rem;
    padding-top: 4rem;
    display: flex;
    flex-direction: column;
}

.phone-menu .phone-menu-links h2 {
    font-size: 1.5rem;
    font-weight: 400;
    margin-bottom: 4rem;
}

.phone-menu .phone-menu-links .link-container {
    margin-bottom: 1rem;
}

.phone-menu .phone-menu-links a {
    position: relative;
}

.phone-menu-links a::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -3px;
    width: 0;
    height: 1px;
    background: var(--white);
    transform: translateX(-50%);
    transition: width 1s ease;
}
.phone-menu-links a:hover::after {
    width: 100%;
}

.word-break {
    display: none;
}

@media screen and (max-width:  1030px) {
    .menu-list.list-right .menu-link:not(:first-child),
    .menu-list.list-left .menu-link:not(:last-child) {
        display: none;
    }

    .static-menu {
        padding-left: 1rem;
    }
    .shop-menu {
        padding-right: 1rem;
    }

    .navbar .logo-container {
        display: none;
    }
    .navbar .menu-icon-container {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    .navbar .shop-menu .menu-list-container,
    .navbar .static-menu .menu-list-container {
        display: none;
    }

    .navbar .menu-icon-container {
        transform: scale(1.2);
    }
}

@media screen and (max-width: 500px) {
    .navbar .company-info .company-link {
        font-size: 4vw !important; 
    }
}

@media screen and (max-width: 375px) {
    .word-break {
        display: inline;
    }
}