.confirmation-container {
    padding-top: 13rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background: #fafafa;
    min-height: 100%;
}

.confirmation-container .icon-container i {
    transform: scale(2);
    margin-bottom: 2rem;
    color: var(--darker-primary);
}

.confirmation-container .thankyou-container h2 {
    font-size: 4rem;
    font-family: var(--secondary-font);
    font-weight: 400;
    margin-bottom: 2rem;
}

.confirmation-container .text-container {
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.confirmation-container .reminder-container {
    margin-bottom: 4rem;
    opacity: 0.6;
}

.reminder-container .bold {
    font-weight: 500;
}
