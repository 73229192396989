.checkout-section {
    padding: 0 1rem;
    margin-top: 2rem;
    min-width:  400px;
    align-self: flex-end;
    margin-bottom: -2rem;
}

.checkout-section h3 {
    font-size: 2rem;
    font-weight: 500;
    color: var(--dark-primary);
    margin-bottom: 2rem;
}

.checkout-section .price {
    font-size: 1.1rem;
    padding-bottom: .5rem;
    border-bottom: 1px solid #3332;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.price .right {
    opacity: 0.5;
}

.checkout-section .price.total-price {
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 2rem;
    margin-top: 1rem;
}

@media screen and (max-width: 500px) {
    .checkout-section {
        margin-top: 3.5rem;
        width: 100%;
        min-width: 0;
    }
}