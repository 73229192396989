.products-banner {
    padding: 9rem 2rem 5rem 2rem;
    margin-top: 3rem;
    background: var(--primary-color);
    color: var(--white);
    overflow: hidden;
}

.intro-container h2 {
    font-size: 3.5rem;
    font-weight: 300;
}

.intro-container p {
    font-size: .9rem;
    text-transform: uppercase;
    font-weight: 500;
    opacity: .7;
}

.intro-container {
    display: flex;
    justify-content: space-between;
}

.intro-container .text-container,
.intro-container .photo-container {
    flex: 1;
}

.photo-container {
    margin-bottom: -5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.photo-container img {
    width: 160px;
    align-self: center;
}

.photo-container img:first-child {
    transform: translateY(20%);
    z-index: 3;
}
.photo-container img:nth-child(2) {
    transform: translateY(-20%) translateX(-20%);
    z-index: 2;
}
.photo-container img:nth-child(3) {
    transform: translateY(50%) translateX(-70%);
    z-index: 1;
}

@media screen and (max-width: 1030px) {
    .photo-container {
        transform: translateX(20%);
    }

    .photo-container img {
        width: 140px;
        align-self: center;
    }

    .photo-container img:first-child {
        transform: translateY(20%);
        z-index: 3;
    }
    .photo-container img:nth-child(2) {
        transform: translateY(-20%) translateX(-20%);
        z-index: 2;
    }
    .photo-container img:nth-child(3) {
        transform: translateY(50%) translateX(-70%);
        z-index: 1;
    }

    .intro-container h2 {
        font-size: 2.5rem;
    }
    .intro-container p {
        font-size: .75rem;
    }
}

@media screen and (max-width: 768px) {
    .intro-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .intro-container .text-container {
        margin-bottom: 5rem;
    }
    .intro-container .photo-container {
        justify-content: center;
    }
    .photo-container {
        transform: translateX(50px);
    }
}

@media screen and (max-width: 500px) {
    .photo-container img {
        width: 100px;
    }
    .photo-container {
        transform: translateX(20px);
    }
}